.please-us-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    padding: 0 16px;
}

.please-us-content h2 {
    margin-bottom: 0.2em;
}

.please-us-ornement {
    height: 1.5em;
}
