.content-drawer {
    width: 540px;
    margin-bottom: 5vh;
    margin-bottom: 5dvh;
    margin-left: calc((100vw - 540px) / 2);
    margin-left: calc((100dvw - 540px) / 2);
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 0.8) !important;

    @media (max-width: 600px) {
        width: 90vw;
        width: 90dvw;
        margin-left: 5vw;
        margin-left: 5dvw;
    }
}

.content-body {
    font-family: Neuton, serif;
    font-weight: 200;
    height: 90vh;
    height: 90dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
        font-family: "Good Vibes", cursive;
        font-weight: 400;
        color: rgb(88, 82, 51);
    }

    h2 {
        font-family: "Lora", serif;
        font-weight: 400;
        font-style: italic;
        color: rgb(88, 82, 51);
    }

    p {
        font-size: 1.2em;
    }

    strong {
        color: rgb(88, 82, 51);
    }

    a {
        color: rgb(88, 82, 51);
        text-decoration: none;
    }
}

.navigation-arrow {
    font-size: 1.5em;
    color: rgb(88, 82, 51);
    font-family: "Good Vibes", cursive;
}

.content-scrollable {
    overflow-y: auto;
    width: 100%;
}
