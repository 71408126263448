.MuiPaper-rounded {
    border-radius: 30px;
}

.MuiPaper-root {
    display: flex;
    flex-direction: column;
}

h2.confirm-popup-title {
    font-family: Great Vibes, cursive;
    font-size: 2.3em;
    background-color: #988d56;
    color: white;
    text-align: center;
}

.confirm-popup-content {
    flex: 1;
    overflow: auto;
    font-family: Neuton, serif;
    font-weight: 200;
    font-size: 1.2em;

    .MuiFormLabel-root {
        font-family: Lora, serif;
        font-style: italic;
        font-size: 0.95em;
        color: #988d56;
        padding-bottom: 6px;
    }

    .MuiFormControlLabel-label, .MuiInputBase-root {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
    }

    .MuiInputBase-input, .MuiOutlinedInput-root {
        padding: 4px;
    }

    .MuiFormControl-root {
        margin-top: 6px;
    }

    .MuiRadio-root {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .MuiFormControlLabel-asterisk {
        display: none;
    }
}

.confirm-popup-useful-information {
    font-family: Lora, serif;
    font-style: italic;
    font-size: 0.88em;
}

.confirm-popup-buttons {
    padding: 0  24px 24px;
}
