.menu {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  background: rgba(152, 141, 86, 0.5);

  @media screen and (min-width: 850px) {
    flex-direction: row;
    justify-content: space-around;
  }
}

.menu > .menu-item {
  font-family: " Lora", serif;
  font-weight: 400;
  font-style: italic;
  font-size: clamp(1.6rem, 2vw, 2.4rem);
  line-height: normal;
  padding-top: 12px;
  padding-bottom: 12px;
}

button.menu-item {
  background-color: rgba(152, 141, 86, 0) !important;
  box-shadow: none;
  text-transform: none;
}

p.menu-item {
  letter-spacing: 0.02857em;
  margin: 12px;
}
