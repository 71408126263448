.dday-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.dday-date {
  text-align: center;
  font-family: Great Vibes, cursive;
  color: #988d56;
  font-size: 2.2em;
}

.dday-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 10px;

    .dday-item-image {
        position: relative;
        width: 35%;
        max-width: 250px;

        &.broglie {
            width: 70%;
        }

        &.truck {
            width: 50%;
        }

        &.drinks {
            width: 25%;
        }

        &.brunch {
            width: 40%;
        }

        img {
            width: 100%;
        }

        span {
            position: absolute;
            top: -10px;
            right: -35px;
            font-family: Great Vibes, cursive;
            color: rgb(88, 82, 51);
            font-size: 2em;
        }

        &.broglie span {
            right: -40px;
        }

        &.truck span {
            right: -20px;
        }

        &.cake span {
            right: -30px;
        }

        &.drinks span {
            right: -50px;
        }

        &.brunch span {
            right: -30px;
        }
    }

    .dday-item-title {
        font-family: Lora, serif;
        font-style: italic;
        font-size: 1.2em;
    }

    .dday-item-subtitle {
        font-size: 1.1em;
        a {
            color: unset;
        }
    }
}
