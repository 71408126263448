.confirm-your-visit-content {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.2em;
}

.confirm-your-visit-inputs {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
}

.confirm-your-visit-input {
    flex: 1;

    & > label, & input {
        font-family: Neuton, serif;
        font-weight: 200;
        font-size: 1em;
        line-height: 1em;
    }
}

.confirm-your-visit-header {
    font-family: Lora,  serif;
    font-style: italic;
    font-size: 1.2em;
    background-color: #988d56;
    color: white;
}

.confirm-your-visit-row {
    font-family: Neuton, serif;
    font-weight: 200;
    font-size: 1.3em;
}

.confirm-your-visit-cell {
    display: flex;
    align-items: center;
}

.confirm-your-visit-radio span {
    font-family: Neuton, serif;
    font-weight: 200;
    font-size: 1em;
}
