.useful-information-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
}

.useful-information-content h2 {
    margin-bottom: 0.2em;
}

.useful-information-ornement {
    height: 1.5em;
}

.useful-information-map-container {
    position: relative;
}

.useful-information-map {
    margin-top: 1em;
    max-width: 200px;
    width: 100%;
}

.useful-information-map-hint {
    position: absolute;
    top: 35%;
    right: 16%;
    width: 10%;
    height: 9%;
    cursor: default;
}
