.App {
  background-image: url("/public/background-high.jpg"), url("/public/background-medium.jpg"), url("/public/background-low.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-position-x: 54%;
  /*background: repeat url("/public/background-side.jpg");*/
  text-align: center;
}

.app-background {
  /*background: no-repeat center transparent url("/public/background.jpg");*/
  min-height: 100vh;
}

.app-top {
  position: fixed;
  display: flex;
  width: 92%;
  height: 40vmin;
  max-height: 400px;
  justify-content: space-between;
  align-items: center;
  margin: 4%;
}

.app-ornement {
  height: 90%;
}

.app-countdown {
  font-size: clamp(1.4rem, 4vw, 2.5rem);
  top: 10%;
}
