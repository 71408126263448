.accomodation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
}

.accomodation-content h2 {
    margin-bottom: 0.2em;
}

.accomodation-ornement {
    height: 1.5em;
}

.accomodation-map-container {
    position: relative;
}

.accomodation-map {
    margin-top: 1em;
    max-width: 200px;
    width: 100%;
}

.accomodation-map-hint {
    position: absolute;
    top: 35%;
    right: 16%;
    width: 10%;
    height: 9%;
    cursor: default;
}

.accomodation-confirm {
    font-family: Lora, serif;
    font-style: italic;
    font-size: 0.88em;
}