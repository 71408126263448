.add-music-content {
    padding-left: 1rem;
    padding-right: 1rem;

    h2 {
        margin-bottom: 0.2em;
    }
}

.add-music-ornement {
    height: 1.5em;
}

.add-music-inputs {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    padding-bottom: 1rem;
}

.add-music-input {
    flex: 1;

    & > label, & input {
        font-family: Neuton, serif;
        font-weight: 200;
        font-size: 1.2em;
        line-height: 1em;
    }
}
